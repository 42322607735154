$error-light: #F4A7A7;
$primary: #5f88ff;
$warning: #FF892A;
$link: #9E54FF;
$danger: #F4A7A7;

$info: #4169bc; 
$family-sans-serif:poppins;
$scheme-main: #0E061C;

@font-face {
    font-family: poppins;
    src: local("PoppinsRegular"), url("./fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"), url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@import "../node_modules/bulma/bulma.sass";

//use primary and info as main color, can create more if needed//
//warning and error use for those purposes and same for link//
