.homeMainContainer {
  
  .backgroundImageLayer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
}

.rocketLayer {
  top: 0;
  left: 0;
  z-index: 1;

    #rocket {
      position: absolute;
      right: 0px;
      top: 45.91%;
      transform: rotate(-1.24deg);
      height: 600px;
    }
}

.contentLayer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 2;
}

// layer 1 elements
#bgStars {
  position:absolute;
  top:0px;
  width:100%;
  opacity: 0.8;
}

#bgImage {
  position:absolute;
  top:0px;
  width:100vw;
  height: inherit;
}

#smoke-overlay {
  position: absolute;
  height:inherit;
  width:100vw
}


//layer 3 elements
#logo {
  height:40px;
  width: auto;
  margin: 16px 0px 24px;
  @media screen and (min-width: 600px) {
    height:70px;
  }
}

form {
  margin-top: 1rem;
  -webkit-text-fill-color: #FFFFFF;
}

p {
  color: #FFFFFF;
}

.taglineContainer {
  display: grid;
}

#tagline1 {
  grid-column: 1;
  grid-row: 1;
  color: #FFFFFF;
  font-size: 32px;
  line-height: 1.2;
  font-family: PoppinsBold;
  font-weight:700;

  @media screen and (min-width: 600px) {
      font-size: 72px
    }
  
    @media screen and (min-width: 800px) {
      font-size: 110px
    }
  }

#tagline2 {
margin-left: 1px;
margin-right:-1px;
grid-column: 1;
grid-row: 1;
-webkit-text-stroke: 1px #FF5C00;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
font-size: 32px;
line-height: 1.2;
font-family: PoppinsBold;
@media screen and (min-width: 600px) {
    font-size: 72px;
    margin-left: 1px;
    margin-right:-1px;
    margin-top: -1px
  }
@media screen and (min-width: 800px) {
    font-size: 110px;
    margin-left: 4px;
    margin-right:-4px;
    margin-top: -2px
  }
}

.spacer {
  height: 15vw;
  width:10%;
}

// Floating label with input height 60px
.control.has-floating-label {
  position: relative;

  // Focused state, when the :placeholder-shown is false
  .label.is-floating-label {
    font-weight: normal;
    position: absolute;
    line-height: 1;
    font-size:0.85rem;
  }

  .input.is-medium, textarea.is-medium {
    height: 3.75rem;
    transition-duration: 0.2s;
    // Non-focused state, placeholder is show.
    // Modern browser only
    &:placeholder-shown + .label.is-floating-label {
      color: #4b5370;
      display: block;
      top: 1.375rem;
      left: 0.75rem;
      z-index: 0;
    }

    &:hover + .label.is-floating-label {
      z-index: 2;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: 1.5rem;

      & + .label.is-floating-label {
        color: #1c2a3f;
        top: 0.5rem;
        left: 0.75rem;
        z-index: 11;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #FFFFFF;
    font-size: .85rem;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #0E061C;
    font-size: 1.25rem;
}

.field.has-addons .control.has-floating-label .input, field.has-addons .control.has-floating-label .textarea {
  position: static;
}


button {
  -webkit-text-fill-color: #0E061C;
}

.getInTouchButton {
  margin-bottom: 32px;
  @media screen and (min-width: 600px) {
    margin-bottom: 176px;
  }
}

.comingsoon {
  margin-top: 72px;
  @media screen and (min-width: 600px) {
    margin-top: 96px;
  }

  @media screen and (min-width: 800px) {
    margin-top: 72px;
}
}
}
