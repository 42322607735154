.homeMainContainer {
    #errorBox {
    background-color: #F4A7A7;
    color:black;
    border-radius: 16px;
}

#successBox {

    color:black;
    border-radius: 16px;
}

.errorBoxText {
    color: black;
}



.errorMessageDiv {
    height:184px;
    margin: 1rem 0rem
}

.headlineFont {
    font-size: 16px;
}

.headlineFontSuccess {
    font-size: 16px;
    color: #257953;
}

.messageFont {
    font-size: 14px;
}

.colorNormal {
    color: #4b5370;
}

.colorError {
    color: #F4A7A7
}}